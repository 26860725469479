@import '@paysafe-ui/components/prebuilt-themes/whitelabel';

$base-font-size: 16px;

html {
  font-size: $base-font-size;
}

body {
  background-color: #3F375A;
}
